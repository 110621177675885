// import Login from "@/Views/Authentication/Login";
// import Register from "@/Views/Authentication/Register";
import { DataRouteObject } from "react-router-dom";

export const publicRoutes: DataRouteObject[] = [
  // {
  //   id: "login",
  //   path: "/login",
  //   Component: Login,
  // },
  // {
  //   id: "register",
  //   path: "/register",
  //   Component: Register,
  // },
];

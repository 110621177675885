import { DataRouteObject } from "react-router-dom";

export const dashboardRoutes: DataRouteObject[] = [
  {
    id: "driver",
    path: "drivers",
    lazy: async () => {
      const module = await import("@/Views/Dashboard/Drivers/DriversLister");
      // const
      return { element: <module.default /> };
    },
    // loader: async () => {
    //   return fetch("https://jsonplaceholder.typicode.com/comments");
    // },
  },
  {
    id: "singleDriver",
    path: "drivers/:driver",
    lazy: async () => {
      const module = await import(
        "@/Views/Dashboard/Drivers/DriverProfile/DriverProfile"
      );
      return { element: <module.default /> };
    },
    // loader: async ({ params }) => {
    //   return fetch(
    //     `https://jsonplaceholder.typicode.com/posts/${params?.driver}`
    //   );
    // },
  },
  {
    id: "joinRequests",
    path: "drivers/join-requests",
    lazy: async () => {
      const module = await import(
        "@/Views/Dashboard/Drivers/JoinRequests/JoinRequests"
      );
      return { element: <module.default /> };
    },
    // loader: async () => {
    //   const api1 = await fetch("https://jsonplaceholder.typicode.com/comments");
    //   const api2 = await fetch("https://jsonplaceholder.typicode.com/posts");
    //   return { data1: await api1.json(), data2: await api2.json() };
    // },
  },
  {
    id: "joinRequests_2",
    path: "join-requests",
    lazy: async () => {
      const module = await import(
        "@/Views/Dashboard/Drivers/JoinRequests/JoinRequests"
      );
      return { element: <module.default /> };
    },
  },
  {
    id: "customers",
    path: "customers",
    lazy: async () => {
      const module = await import(
        "@/Views/Dashboard/Customers/CustomersLister"
      );
      return { element: <module.default /> };
    },
  },
  {
    id: "singleCustomers",
    path: "customers/:customer",
    lazy: async () => {
      const module = await import(
        "@/Views/Dashboard/Customers/CustomerProfile/CustomerProfile"
      );
      return { element: <module.default /> };
    },
  },
  {
    id: "shifts",
    path: "shifts",
    lazy: async () => {
      const module = await import("@/Views/Dashboard/Shifts/Shifts");
      return { element: <module.default /> };
    },
  },
  {
    id: "trips",
    path: "trips",
    lazy: async () => {
      const module = await import("@/Views/Dashboard/Trips/Trips");
      return { element: <module.default /> };
    },
  },
  {
    id: "singleShift",
    path: "shifts/:shift",
    lazy: async () => {
      const module = await import("@/Views/Dashboard/Shifts/ShiftProfile");
      return { element: <module.default /> };
    },
  },
  {
    id: "singleTrip",
    path: "trips/:trip",
    lazy: async () => {
      const module = await import("@/Views/Dashboard/Trips/TripProfile/TripProfile");
      return { element: <module.default /> };
    },
  },
];

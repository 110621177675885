import { DataRouteObject } from "react-router-dom";
// import Dashboard from "@/Views/Dashboard/Dashboard";

export const protectedRoutes: DataRouteObject[] = [
  // {
  //   path: "/dashboard",
  //   exact: false,
  //   breadcrumb: "Dashboard",
  //   Component: Dashboard,
  // },
];

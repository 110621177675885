import { Outlet, Navigate } from "react-router-dom";
import sideImage from "@/assets/images/taxi_22.jpg";
import { useEffect } from "react";
const AuthLayout = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  useEffect(() => {
    // Apply saved theme from localStorage or use default
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      // setTheme(savedTheme);
      document.querySelector("html")?.setAttribute("data-theme", savedTheme);
    } else
      document.querySelector("html")?.setAttribute("data-theme", "green 3");
  }, []);
  return (
    <>
      {isAuthenticated ? (
        <Navigate to="/" />
      ) : (
        <>
          <section className="flex flex-1 flex-col justify-center items-center p-10">
            <Outlet />
          </section>
          <img
            src={sideImage}
            alt="logo"
            className="hidden xl:block h-screen w-1/2 object-cover bg-no-repeat"
          />
        </>
      )}
    </>
  );
};

export default AuthLayout;
